import { Auth } from 'aws-amplify';
import React from 'react';
import { MESSAGES } from '../helpers/errorMessages/messages';
import type { MessageKeys } from '../helpers/errorMessages/messages';
import type { CognitoUser } from 'amazon-cognito-identity-js';
import type { ISignUpResult } from 'amazon-cognito-identity-js';

export type AmplifyAuthResponse<T = unknown> = {
  data: T | null;
  errorCode: string;
  errorMessage: string;
};

function getResponse<T = unknown>(data: T) {
  return {
    data: data ?? null,
    errorCode: '',
    errorMessage: '',
  };
}

function getErrorResponse(args: { key?: MessageKeys; error: unknown }) {
  const errorCode = args.error instanceof Error ? args.error.name : '';
  const messageList = args.key ? MESSAGES[args.key] : [];
  const errorMessage =
    messageList[errorCode as keyof typeof messageList] ??
    `${MESSAGES.default}(${errorCode})`;

  return {
    data: null,
    errorCode,
    errorMessage,
  };
}

export type ForgotPasswordApiResponse = AmplifyAuthResponse<{
  CodeDeliveryDetails: {
    AttributeName: string;
    DeliveryMedium: string;
    Destination: string;
  };
}>;

export type SignUpStartApiResponse = AmplifyAuthResponse<ISignUpResult>;

export function useAmplifyAuth() {
  const signIn = React.useCallback(
    ({
      loginId,
      password,
    }: {
      loginId: string;
      password: string;
    }): Promise<AmplifyAuthResponse> =>
      Auth.signIn(loginId, password)
        .then(getResponse)
        .catch((error) =>
          getErrorResponse({
            key: 'authSignIn',
            error,
          })
        ),
    []
  );

  const signOut = React.useCallback(
    (): Promise<AmplifyAuthResponse> =>
      Auth.signOut()
        .then(getResponse)
        .catch((error) =>
          getErrorResponse({
            error,
          })
        ),
    []
  );

  const signUp = React.useCallback(
    ({
      loginId,
      password,
      email,
    }: {
      loginId: string;
      password: string;
      email: string;
    }): Promise<SignUpStartApiResponse> =>
      Auth.signUp({
        username: loginId,
        password,
        attributes: {
          email,
        },
      })
        .then(getResponse)
        .catch((error) =>
          getErrorResponse({
            key: 'authSignUp',
            error,
          })
        ),
    []
  );

  const verifyCurrentUserAttribute = React.useCallback(
    ({ attr }: { attr: 'email' }): Promise<AmplifyAuthResponse> =>
      Auth.verifyCurrentUserAttribute(attr)
        .then(getResponse)
        .catch((error) =>
          getErrorResponse({
            error,
          })
        ),
    []
  );

  const currentAuthenticatedUser = React.useCallback(
    (): Promise<AmplifyAuthResponse<CognitoUser>> =>
      Auth.currentAuthenticatedUser({ bypassCache: true })
        .then(getResponse)
        .catch((error) =>
          getErrorResponse({
            error,
          })
        ),
    []
  );

  const updateUserAttributes = React.useCallback(
    ({
      user,
      email,
    }: {
      user: unknown;
      email: string;
    }): Promise<AmplifyAuthResponse> =>
      Auth.updateUserAttributes(user, {
        email,
      })
        .then(getResponse)
        .catch((error) =>
          getErrorResponse({ key: 'authUpdateUserAttributes', error })
        ),
    []
  );

  const verifyCurrentUserAttributeSubmit = React.useCallback(
    ({
      attr,
      verificationCode,
    }: {
      attr: 'email';
      verificationCode: string;
    }): Promise<AmplifyAuthResponse> =>
      Auth.verifyCurrentUserAttributeSubmit(attr, verificationCode)
        .then(getResponse)
        .catch((error) =>
          getErrorResponse({
            key: 'authVerifyCurrentUserAttributeSubmit',
            error,
          })
        ),
    []
  );

  const forgotPassword = React.useCallback(
    ({ loginId }: { loginId: string }): Promise<ForgotPasswordApiResponse> =>
      Auth.forgotPassword(loginId)
        .then(getResponse)
        .catch((error) =>
          getErrorResponse({
            key: 'authForgotPassword',
            error,
          })
        ),
    []
  );

  const forgotPasswordSubmit = React.useCallback(
    ({
      loginId,
      verificationCode,
      newPassword,
    }: {
      loginId: string;
      verificationCode: string;
      newPassword: string;
    }): Promise<AmplifyAuthResponse> =>
      Auth.forgotPasswordSubmit(loginId, verificationCode, newPassword)
        .then(getResponse)
        .catch((error) =>
          getErrorResponse({
            key: 'authForgotPasswordSubmit',
            error,
          })
        ),
    []
  );

  const resendSignUp = React.useCallback(
    ({ loginId }: { loginId: string }): Promise<AmplifyAuthResponse> =>
      Auth.resendSignUp(loginId)
        .then(getResponse)
        .catch((error) =>
          getErrorResponse({
            key: 'authResendSignUp',
            error,
          })
        ),
    []
  );

  const confirmSignUp = React.useCallback(
    ({
      loginId,
      verificationCode,
    }: {
      loginId: string;
      verificationCode: string;
    }): Promise<AmplifyAuthResponse> =>
      Auth.confirmSignUp(loginId, verificationCode)
        .then(getResponse)
        .catch((error) =>
          getErrorResponse({
            key: 'authConfirmSignUp',
            error,
          })
        ),
    []
  );

  const deleteUser = React.useCallback(
    (): Promise<AmplifyAuthResponse> =>
      Auth.deleteUser()
        .then(getResponse)
        .catch((error) =>
          getErrorResponse({
            error,
          })
        ),
    []
  );

  return {
    signIn,
    signOut,
    signUp,
    confirmSignUp,
    verifyCurrentUserAttribute,
    currentAuthenticatedUser,
    updateUserAttributes,
    verifyCurrentUserAttributeSubmit,
    forgotPassword,
    forgotPasswordSubmit,
    resendSignUp,
    deleteUser,
  };
}
